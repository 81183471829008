<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="daily-reward-box rewardPhoto">
         <div class="wrapDiv">
            <div class="coin-box">
               <div class="coin-text">
                  <div class="text-white"
                     @click="redirectProfile(thisWeekData[1].id, thisWeekData[1].profile_id, thisWeekData[1].charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img :src='zhibojianFansSecond' alt="Rank 2 Section Image" />
                           <div class="coin-profPic" v-if="thisWeekData.length > 1">
                              <img class="overlapImg" :src='thisWeekData[1].profile_images[0].image_name'
                                 alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank2' alt="Rank 2" />
                        <div class="pointsBox" v-if="thisWeekData.length > 1">
                           <img class="points" :src='thisWeekData[1].current_charm_level_icon' alt="Label Image" />
                           <span class="value">{{ thisWeekData[1].charm_level }}</span>
                        </div>
                     </div>
                     <div class="mt-20" v-if="thisWeekData.length > 1" style="margin-top: 10px !important;">
                        <span class="name">
                           <div :class="{ 'marqueeContainer': thisWeekData[1].name.length > 8 }">
                              <div v-if="thisWeekData[1].name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ thisWeekData[1].name }}</span>
                              </div>
                              <div v-else>{{ thisWeekData[1].name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(thisWeekData[1].privatecall_weekly_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards ne-btn" v-if="thisWeekData[1].call_performance <= 40"
                           style="margin-left: 10px;">Not Eligible - {{ thisWeekData[1].call_performance }}</span>
                     </div>
                  </div>
               </div>
               <div class="coin-text active">
                  <div class="text-white"
                     @click="redirectProfile(thisWeekData[0].id, thisWeekData[0].profile_id, thisWeekData[0].charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img class="activeZoom" :src='zhibojianFansFirst' alt="" />
                           <div class="coin-profPicActive" v-if="thisWeekData.length > 0">
                              <img class="overlapImg overlapActive" :src='thisWeekData[0].profile_images[0].image_name'
                                 alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank1' alt="Rank 1" />
                        <div class="pointsBox" v-if="thisWeekData.length > 0">
                           <img class="points" :src='thisWeekData[0].current_charm_level_icon' alt="Label Image">
                           <span class="value">{{ thisWeekData[0].charm_level }}</span>
                        </div>
                     </div>
                     <div class="mt-20" v-if="thisWeekData.length > 0" style="margin-top: 10px !important;">
                        <span class="name" style="text-align: center;">
                           <div :class="{ 'marqueeContainer': thisWeekData[0].name.length > 8 }">
                              <div v-if="thisWeekData[0].name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ thisWeekData[0].name }}</span>
                              </div>
                              <div v-else>{{ thisWeekData[0].name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box" style="text-align: center;">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(thisWeekData[0].privatecall_weekly_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards ne-btn" v-if="thisWeekData[0].call_performance <= 40"
                           style="margin-left: 10px;">Not Eligible - {{ thisWeekData[0].call_performance }}</span>
                     </div>
                  </div>
               </div>
               <div class="coin-text">
                  <div class="text-white"
                     @click="redirectProfile(thisWeekData[2].id, thisWeekData[2].profile_id, thisWeekData[2].charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img :src='zhibojianFansThird' alt="" />
                           <div class="coin-profPic" v-if="thisWeekData.length > 2">
                              <img class="overlapImg" :src='thisWeekData[2].profile_images[0].image_name'
                                 alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank3' alt="Rank 3" />
                        <div class="pointsBox" v-if="thisWeekData.length > 2">
                           <img class="points" :src='thisWeekData[2].current_charm_level_icon' alt="Label Image">
                           <span class="value">{{ thisWeekData[2].charm_level }}</span>
                        </div>
                     </div>
                     <div class="mt-20" v-if="thisWeekData.length > 2" style="margin-top: 10px !important;">
                        <span class="name">
                           <div :class="{ 'marqueeContainer': thisWeekData[2].name.length > 8 }">
                              <div v-if="thisWeekData[2].name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ thisWeekData[2].name }}</span>
                              </div>
                              <div v-else>{{ thisWeekData[2].name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(thisWeekData[2].privatecall_weekly_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards ne-btn" v-if="thisWeekData[2].call_performance <= 40"
                           style="margin-left: 10px;">Not Eligible - {{ thisWeekData[2].call_performance }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="podium">
               <img class="podiumImg" :src='TCPodiumBG' alt="Podium Image" />
            </div>
         </div>
      </div>
      <div class="listingPattern">
         <div class="fixHeight">
            <div class="listBoxes" v-for="(reward, index) in thisWeekData.slice(3)" :key="reward.id"
               @click="redirectProfile(reward.id, reward.profile_id, reward.charm_level)">
               <div class="leftBxb">
                  <h4>{{ index + 4 }}</h4>
                  <div class="listIconBox">
                     <div class="profPic">
                        <img
                           :src='(reward.profile_images.length > 0) ? reward.profile_images[0].image_name : defaultUserImage'
                           class="user" alt="User Image" />
                     </div>
                     <img class="frames" :src='reward.current_pic_frame' alt="" />
                  </div>
                  <span>
                     <div :class="{ 'marqueeContainer': reward.name.length > 8 }">
                        <div v-if="reward.name.length > 8" class="marquee">
                           <span class="marquee-content">{{ reward.name }}</span>
                        </div>
                        <div v-else>{{ reward.name }}</div>
                     </div>
                     <div class="base">
                        <img :src='reward.current_charm_level_icon' alt="" /><b>{{ reward.charm_level }}</b>
                     </div>
                  </span>
               </div>
               <div class="rightBxb">
                  <span>
                     <img :src='yellowClockIcon' alt="Clock Icon" />
                     {{ Math.floor(reward.privatecall_weekly_duration / 60) }}
                     <span class="minTxt">min</span>
                  </span>
                  <div class="rewards ne-btn" v-if="reward.call_performance <= 40" style="margin-left: 10px;">Not
                     Eligible - {{ reward.call_performance }}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import TCPodiumBG from '../../assets/images/TC-podium-bg.png';
import yellowClockIcon from '../../assets/images/yellowClock-Icon.png'
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png'
import rank2 from '../../assets/images/rank-2.png'
import rank3 from '../../assets/images/rank-3.png'
import TCLabelBG from '../../assets/images/TC-label-bg.png';
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopCallThisWeekTabCont',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         isRewardPopupVisible: false,
         thisWeekData: [],
         TCPodiumBG: TCPodiumBG,
         yellowClockIcon: yellowClockIcon,
         zhibojianFansFirst,
         zhibojianFansSecond,
         zhibojianFansThird,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         TCLabelBG: TCLabelBG,
         defaultUserImage: defaultUserImage,
         loading: false,
      }
   },
   mounted() {
      this.fetchWeeklyRewards();
   },
   methods: {
      fetchWeeklyRewards() {
         this.loading = true;
         axios.get('api/top-call-weekly?type=this_week').then(response => {
            this.thisWeekData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error('Error featching current Week data:', error)
            })
            .finally(() => {
               this.loading = false;
            })
      },
      checkAllImagesExist() {
         this.thisWeekData.forEach(reward => {
            if (reward.profile_images.length > 0) {
               const imageUrl = reward.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }  else {
                  reward.profile_images = [{ image_name: this.defaultUserImage }];
               }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },
      // Get User info on click 
      redirectProfile(id, profile_id, level) {
         // if (window.navigator.userAgent.indexOf("Android") > -1) {
         // const baseURL = 'female-weekly-rewards';
         const baseURL = '#';
         const params = {
            id: id,
            profile_id: profile_id,
            level: level
         };

         const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
         const url = `${baseURL}?${queryString}`;
         window.location.href = url;
         // Android.redirectProfile(url);
         // }
      }
   }
}
</script>

<style>
.rewards.ne-btn {
   display: inline-block;
   background: #fff;
   font-size: 9px !important;
   line-height: 11px;
   color: #FF7700 !important;
   padding: 3px 6px;
   margin-top: 2px;
   border-radius: 25px;
   font-weight: 700 !important;
   font-family: 'Segoe UI Bold';
}

.topCall .listBoxes .rightBxb span .rewards.ne-btn {
   top: -3px;
}
</style>

