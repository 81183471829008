<template>
    <div class="rainContainer">
        <div class="backgroundWrapper">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">Win Rate Rank</h2>
                    </div>
                </div>
            </div>
            <div class="winRateRank-listIn">
                <ul>
                    <li>
                        <div class="car-imgBox">
                            <img :src="SUV" alt="SUV">
                        </div>
                        <div class="carName">SUV</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">15.52%</div>
                            <div class="carRatingBox">2.3</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="Car" alt="Car">
                        </div>
                        <div class="carName">Car</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">15.14%</div>
                            <div class="carRatingBox">2.3</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="ORV" alt="ORV">
                        </div>
                        <div class="carName">ORV</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">13.27%</div>
                            <div class="carRatingBox">2.3</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="StockCar" alt="Stock Car">
                        </div>
                        <div class="carName">Stock Car</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">12.61%</div>
                            <div class="carRatingBox">2.8</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="MonsterTruck" alt="Monster Truck">
                        </div>
                        <div class="carName">Monster Truck</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">12.54%</div>
                            <div class="carRatingBox">2.6</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="SportsCar" alt="Sports Car">
                        </div>
                        <div class="carName">Sports Car</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">9.01%</div>
                            <div class="carRatingBox">3.4</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="ATV" alt="ATV">
                        </div>
                        <div class="carName">ATV</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">8.87%</div>
                            <div class="carRatingBox">3.7</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="Supercar" alt="Supercar">
                        </div>
                        <div class="carName">Supercar</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">7.26%</div>
                            <div class="carRatingBox">3.8</div>
                        </div>
                    </li>

                    <li>
                        <div class="car-imgBox">
                            <img :src="Motorcycle" alt="Motorcycle">
                        </div>
                        <div class="carName">Motorcycle</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">5.79%</div>
                            <div class="carRatingBox">5.4</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import ATV from '../../assets/images/car-game/ATV.png'
import Car from '../../assets/images/car-game/Car.png'
import MonsterTruck from '../../assets/images/car-game/MonsterTruck.png'
import Motorcycle from '../../assets/images/car-game/Motorcycle.png'
import ORV from '../../assets/images/car-game/ORV.png'
import SportsCar from '../../assets/images/car-game/SportsCar.png'
import StockCar from '../../assets/images/car-game/StockCar.png'
import Supercar from '../../assets/images/car-game/Supercar.png'
import SUV from '../../assets/images/car-game/SUV.png'

export default {
    name: 'CarGameWinRateRank',
    data() {
        return {
            ATV: ATV,
            Car: Car,
            MonsterTruck: MonsterTruck,
            Motorcycle: Motorcycle,
            ORV: ORV,
            SportsCar: SportsCar,
            StockCar: StockCar,
            Supercar: Supercar,
            SUV: SUV
        }
    },
    methods: {
        //Close Webview on back arrow click

        viewClose(status) {
            if (window.Android && typeof Android.viewClose === "function") {
                Android.viewClose(status);
                // console.log("mobile close");
            } else {
                window.parent.postMessage({ action: "closePopup", status }, "*");
                // console.log("close - Web");
            }
        }

        // viewClose(status) {
        //     if (window.navigator.userAgent.indexOf("Android") > -1) {
        //         Android.viewClose(status);      
        //         console.log('mobile close');                
        //     }
        //     else {
        //         window.parent.postMessage({ action: "closePopup", status }, "*");
        //         console.log("close - Web");
        //         // document.getElementById("close").addEventListener("click", function () {
        //         //     window.parent.postMessage({ action: "closePopup" }, "*");
        //         //     console.log('web close');
        //         // });
        //     }
        // }

        // viewClose(status) {
        //     window.parent.postMessage({ action: "closePopup", status }, "*");
        //         console.log("close - Web");
        // }
    }
}
</script>