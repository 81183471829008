<template>
    <div>
        <div class="listToggle awwardsLead">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" @click="showTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'thisweek' }"
                        @click="activateTab('thisweek')" data-toggle="tab" href="#thisweek" role="tab">This Week</div>
                </li>
                <li class="nav-item" @click="hideTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'lastweek' }"
                        @click="activateTab('lastweek')" data-toggle="tab" href="#lastweek" role="tab">Last Week</div>
                </li>
            </ul>
            <div class="timesBox" v-show="isTimesBoxVisible">
                <CounterdownCompo :isVisible="true" :showDays="true" />
            </div>
        </div>
        <div class="rewards-bg">
            <h3 class="txtb">{{ activeTab === 'thisweek' ? 'This Week' : 'Last Week' }}</h3>
            <img class="awwwards-icon" :src='topRichAwwwrdsIcon' alt="Awwwards icon"
            @click="showRewardPopup" />
            <img class="date-bg" :src='topRichBarBg' alt="Rich Bar Background" />
            <div class="tab-content">
                <div class="tab-pane" :class="{ 'active': activeTab === 'thisweek' }" id="thisweek" role="tabpanel">
                    <div class="listingPattern" v-if="isLoadedThisWeek">
                        <TopRichThisWeekListDetailsComp />
                    </div>
                </div>
                <div class="tab-pane" :class="{ 'active': activeTab === 'lastweek' }" id="lastweek" role="tabpanel">
                    <div class="listingPattern" v-if="isLoadedLastWeek">
                        <TopRichLastWeekListDetailsComp />
                    </div>
                </div>
            </div>
        </div>
        <!-- Reward Popup -->
        <div class="mode-area" v-show="isPopupOverlayVisible"></div>
        <div :class="{ 'rewardPopupWrapper': true, 'transform-in': rewardsPopupVisible, 'transform-out': !rewardsPopupVisible }">
            <div class="rewardPopupInner scale-up-bottom">
                <h4 class="h4">CURRENT REWARD</h4>
                <img :src='popupClosed' class="closed" @click="showRewardPopup">
                <div class="rewardPopupContBox">
                    <div class="coin-box">
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown2' alt="">
                                </div>
                                <img class="base base2" :src='crownBase1' alt="R1">
                            </div>
                            <span class="name" v-if="currentRewardData.length > 1">{{ currentRewardData[1].reward_coin
                                }}%</span>
                            <span class="spanBox">OF TOTAL SPENDING</span>
                        </div>
                        <div class="coin-text active">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown1' alt="">
                                </div>
                                <img class="base base2" :src='crownBase1' alt="R1">
                            </div>
                            <span class="name" v-if="currentRewardData.length > 0">{{ currentRewardData[0].reward_coin
                                }}%</span>
                            <span class="spanBox">OF TOTAL SPENDING</span>
                        </div>
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown3' alt="">
                                </div>
                                <img class="base base2" :src='crownBase1' alt="R1">
                            </div>
                            <span class="name" v-if="currentRewardData.length > 2">{{ currentRewardData[2].reward_coin
                                }}%</span>
                            <span class="spanBox">OF TOTAL SPENDING</span>
                        </div>
                    </div>
                    <div class="rank-box">
                        <span>4th to 10th</span>
                        <div class="righticon" v-if="currentRewardData.length > 3">{{ currentRewardData[3].reward_coin
                            }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Reward Popup End -->
    </div>
</template>
<script>
import axios from 'axios';
import TopRichThisWeekListDetailsComp from './TopRichThisWeekListDetailsComp.vue';
import TopRichLastWeekListDetailsComp from './TopRichLastWeekListDetailsComp.vue';
import CounterdownCompo from '../../components/CounterdownCompo.vue';
import topRichAwwwrdsIcon from '../../assets/images/TA-awards-icon.png';
import topRichBarBg from '../../assets/images/rich-bar-bg.png';
import popupClosed from '../../assets/images/popupClosed.svg';
import crown1 from '../../assets/images/crown_1.png';
import crown2 from '../../assets/images/crown_2.png';
import crown3 from '../../assets/images/crown_3.png';
import crownBase1 from '../../assets/images/crown_base_1.png';

export default {
    name: 'TopRich',
    components: {
        TopRichThisWeekListDetailsComp,
        TopRichLastWeekListDetailsComp,
        CounterdownCompo,
    },
    data() {
        return {
            isTimesBoxVisible: true,
            activeTab: 'thisweek',
            isLoadedThisWeek: false,
            isLoadedLastWeek: false,
            rewardsPopupVisible: false,
            isPopupOverlayVisible: false,
            currentRewardData: [],
            topRichAwwwrdsIcon: topRichAwwwrdsIcon,
            topRichBarBg: topRichBarBg,
            popupClosed: popupClosed,
            crown1: crown1,
            crown2: crown2,
            crown3: crown3,
            crownBase1: crownBase1,
        };
    },
    mounted() {
        this.fetchWeeklyCurrentReward();
        this.isLoadedThisWeek = true;
    },
    methods: {
        showTimesBox() {
            this.isTimesBoxVisible = true;
        },
        hideTimesBox() {
            this.isTimesBoxVisible = false;
        },
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'thisweek' && !this.isLoadedThisWeek) {
                this.isLoadedThisWeek = true;
            }
            if (tabId === 'lastweek' && !this.isLoadedLastWeek) {
                this.isLoadedLastWeek = true;
            }
        },
        showRewardPopup() {
            this.rewardsPopupVisible = !this.rewardsPopupVisible;
            this.isPopupOverlayVisible = !this.isPopupOverlayVisible;
        },
        fetchWeeklyCurrentReward() {
            axios.get('api/top-rich-weekly').then(response => {
                this.currentRewardData = response.data.result.weeklyrewards;
            })
                .catch(error => {
                    console.error("Error Featching Current Rewards Data", error)
                })
        }

    },
}
</script>