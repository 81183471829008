<template>
    <div>
        <div class="listToggle awwardsLead">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'today' }" @click="activateTab('today')" data-toggle="tab" href="#today" role="tab">Today</div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'yesterday' }" @click="activateTab('yesterday')" data-toggle="tab" href="#yesterday" role="tab">Yesterday</div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'today' }" id="today" role="tabpanel">
                <div class="rewards-bg">
                    <TopGameLuckySevenTodayTabCont />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'yesterday' }" id="yesterday" role="tabpanel">
                <div class="rewards-bg">
                    <TopGameLuckySevenYesterdayTabCont />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TopGameLuckySevenTodayTabCont from './TopGameLuckySevenTodayTabCont.vue';
import TopGameLuckySevenYesterdayTabCont from './TopGameLuckySevenYesterdayTabCont.vue';

export default {
    name: 'TopGameLuckySevenTabs',
    components: {
        TopGameLuckySevenTodayTabCont,
        TopGameLuckySevenYesterdayTabCont
    },
    data() {
        return {
            activeTab: 'today',            
        };
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
        },
    }
}
</script>
