<template>
    <div class="historyDetails-wrapper flipCoinHistoryDetails">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>Rule 25</h2>
            </div>
        </div>
        <div class="ruleInfo-wrapper">
            <div class="ruleContent">
                <p>Set a maximum and minimum bet limit for each round of the game. This ensures that players can only wager within a specified range, preventing excessively high or low bets.</p>
                <p>Ensure that the coin flip is fair and unbiased. This can be achieved through using a physical coin or implementing a random number generator that accurately simulates a fair coin flip.</p>
                <p>Clearly define the payout structure so players know how much they stand to win if their bet is successful. In this case, the winner should receive double the amount they bet.</p>
                <p>Maintain transparency throughout the game by displaying the results of each coin flip openly to all players. This helps build trust and ensures that the game is conducted fairly without any manipulation.</p>                               
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FlipCoinRules',
    methods: {
        viewClose(status) {
            if (window.Android && typeof Android.viewClose === "function") {
                Android.viewClose(status);
            } else {
                window.parent.postMessage({ action: "closePopup", status }, "*");
            }
        }
        // viewClose(status) {
        //     if (window.navigator.userAgent.indexOf("Android") > -1) {
        //         Android.viewClose(status);        
        //     }
        // }
    }
}
</script>